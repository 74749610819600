import Swatches from 'components/ProductCard/Swatches';
import ProductImage from 'components/ProductImage';
import { Special, Title } from 'components/Typography';
import { ResponsiveValue } from 'lib/toResponsiveValue';
import { formatAsCurrency, generateProductImageAltText } from 'lib/util';
import { ImageModel, PriceModel } from 'models/variants/types';
import RouterLink from 'next/link';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface ProductCardProps {
  className?: string;
  url: string;
  name: string;
  images: ImageModel[];
  swatches?: Record<string, string>;
  tag?: string;
  isPreorder?: boolean;
  price: Partial<PriceModel>;
  index?: number;
}

const imageSpans: ResponsiveValue<number> = { xs: 2, l: 4 };

const Figure = styled.figure`
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 5;
  overflow: hidden;
  margin: 0;
`;

const SwappedImage = styled(ProductImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 300ms;
`;

const CardWrapper = styled.div`
  display: block;
  position: relative;
  text-decoration: none;
  height: 100%;
  z-index: 0;

  @media (hover: hover) {
    &:hover ${SwappedImage} {
      opacity: 1;
    }
  }
`;

export const Description = styled.div``;

const Name = styled(Title)`
  display: block;
  display: -webkit-box;
  height: 34px;
  margin-top: 16px;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Link = styled(RouterLink)`
  text-decoration: none;
`;
const Tag = styled(Special)`
  margin-top: 4px;
  display: block;
  height: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Price = styled(Special)`
  display: block;
  height: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

const PreviousPrice = styled.span(
  ({ theme: { colors } }) => css`
    color: ${colors.grey7};
    text-decoration: line-through;
  `
);

const PreviousPricePercentage = styled.span(
  ({ theme: { colors } }) => css`
    color: ${colors.grey7};
  `
);

export const ProductCardWithoutLink: FC<ProductCardProps> = ({
  className,
  name,
  images = [],
  swatches = {},
  tag = '',
  isPreorder = false,
  price,
  index = 0,
}) => (
  <CardWrapper className={className} id={`product-card-${index}`}>
    <Figure>
      {images[0] ? (
        <ProductImage
          src={images[0].url}
          loading={index >= 4 ? 'lazy' : undefined}
          alt={generateProductImageAltText(name, 0, images[0].label)}
          spans={imageSpans}
        />
      ) : null}
      {images[1] ? (
        <SwappedImage
          src={images[1].url}
          loading={index >= 4 ? 'lazy' : undefined}
          alt={generateProductImageAltText(name, 1, images[1].label)}
          spans={imageSpans}
        />
      ) : null}
    </Figure>
    <Description>
      <Name variant="1" size="2XS">
        {name}
      </Name>
      {tag && (
        <Tag variant="2" size="M">
          {tag}
        </Tag>
      )}
      {isPreorder && (
        <Tag variant="2" size="M">
          Pre order
        </Tag>
      )}
      {price?.discountedPrice !== undefined && (
        <Price variant="3" size="L">
          {formatAsCurrency(price.currencyCode, price.discountedPrice)}
          {price.regularPrice !== undefined &&
            price.discountedPrice < price.regularPrice && (
              <>
                <PreviousPrice>
                  {formatAsCurrency(price.currencyCode, price.regularPrice)}
                </PreviousPrice>
                <PreviousPricePercentage>
                  {` -${Math.round(
                    100 - (price.discountedPrice / price.regularPrice) * 100
                  )}%`}
                </PreviousPricePercentage>
              </>
            )}
        </Price>
      )}
      <Swatches swatches={swatches} />
    </Description>
  </CardWrapper>
);

const LinkedProductCard: FC<ProductCardProps> = props => (
  <Link href={props.url}>
    <ProductCardWithoutLink {...props} />
  </Link>
);

export default LinkedProductCard;
