import Contributors from 'components/Contributors';
import DashHudsonUGC from 'components/DashHudsonUGC';
import Gap from 'components/Gap';
import { GapSize } from 'components/Gap/Gap';
import Header from 'components/Header';
import Hero from 'components/Hero';
import HeroCarousel from 'components/HeroCarousel/HeroCarousel';
import HeroHalf from 'components/HeroHalf';
import NewsletterForm from 'components/NewsletterForm';
import Outfit from 'components/Outfit';
import Picture from 'components/Picture';
import ProductGrid from 'components/ProductGrid';
import ProductMosaic from 'components/ProductMosaic';
import ProductRail from 'components/ProductRail';
import RichText from 'components/RichText';
import SizeGuideTabs from 'components/SizeGuideTabs/SizeGuideTabs';
import SplitHero from 'components/SplitHero';
import Stories from 'components/Stories';
import StoriesOverlay from 'components/StoriesOverlay';
import TwoColumnsListing from 'components/TwoColumnsListing';
import { ResponsiveValue } from 'lib/toResponsiveValue';
import { RichTextModel } from 'models/contentful/richText/types';
import { TwoColumnsListingModel } from 'models/contentful/twoColumnsListing/types';
import { ContentPageModel, ContentPageSlot } from 'models/contentPages/types';
import { FC, ReactNode } from 'react';

export const getSpacerSize = (
  previousBlock: ContentPageSlot['blockType'] | null,
  currentBlock: ContentPageSlot['blockType']
): ResponsiveValue<GapSize> | null => {
  if (
    !previousBlock ||
    (currentBlock === 'Hero' && previousBlock === 'Hero') ||
    (currentBlock === 'HeroHalf' && previousBlock === 'HeroHalf') ||
    (currentBlock === 'Hero' && previousBlock === 'HeroCarousel') ||
    (currentBlock === 'HeroHalf' && previousBlock === 'HeroCarousel')
  ) {
    return null;
  }

  if (previousBlock === 'Header') {
    return { xs: 32 };
  }

  if (previousBlock === 'ProductMosaic' && currentBlock === 'ProductGrid') {
    return { xs: 32, l: 48 };
  }

  if (previousBlock === 'ProductGrid' && currentBlock === 'ProductMosaic') {
    return { xs: 32, l: 48 };
  }

  return { xs: 64, l: 96 };
};

const ContentPageSlots: FC<
  {
    slots: ContentPageSlot[];
  } & Pick<ContentPageModel, 'displayMode'>
> = ({ slots, displayMode }) => (
  <>
    {slots.reduce<ReactNode[]>((acc, slot, index, arr) => {
      const spacerSize = getSpacerSize(
        arr[index - 1]?.blockType,
        slot.blockType
      );

      if (spacerSize) {
        acc.push(<Gap key={`${index}-spacer`} size={spacerSize} />);
      }

      switch (slot.blockType) {
        case 'Stories':
          acc.push(<Stories key={index} {...slot} />);
          return acc;

        case 'StoriesOverlay':
          acc.push(<StoriesOverlay key={index} {...slot} />);
          return acc;

        case 'HeroCarousel':
          acc.push(
            <HeroCarousel key={index} displayMode={displayMode} {...slot} />
          );
          return acc;

        case 'Hero':
          acc.push(<Hero key={index} {...slot} />);
          return acc;

        case 'DashHudsonUGC':
          acc.push(<DashHudsonUGC key={index} {...slot} />);
          return acc;

        case 'HeroHalf':
          acc.push(<HeroHalf key={index} {...slot} />);
          return acc;

        case 'TwoColumnsListing':
          acc.push(
            <TwoColumnsListing
              key={index}
              {...(slot as TwoColumnsListingModel)}
            />
          );
          return acc;

        case 'ProductRail':
          acc.push(<ProductRail key={index} {...slot} />);
          return acc;

        case 'ProductMosaic':
          acc.push(<ProductMosaic key={index} {...slot} />);
          return acc;

        case 'RichText':
          acc.push(<RichText key={index} {...(slot as RichTextModel)} />);
          return acc;

        case 'SplitHero':
          acc.push(
            <SplitHero key={index} displayMode={displayMode} {...slot} />
          );
          return acc;

        case 'Subscribe':
          acc.push(<NewsletterForm key={index} inline={true} />);
          return acc;

        case 'ProductGrid':
          acc.push(<ProductGrid key={index} {...slot} />);
          return acc;

        case 'Picture':
          acc.push(<Picture key={index} {...slot} />);
          return acc;

        case 'Outfit':
          acc.push(<Outfit key={index} {...slot} />);
          return acc;

        case 'Contributors':
          acc.push(<Contributors key={index} {...slot} />);
          return acc;

        case 'Header':
          acc.push(<Header key={index} headerLevel="h2" {...slot} />);
          return acc;

        case 'SizeGuideTabs':
          acc.push(<SizeGuideTabs key={index} {...slot} />);
          return acc;

        default:
          return acc;
      }
    }, [])}
  </>
);

export default ContentPageSlots;
